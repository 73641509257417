<template>
  <div>
    <section v-if="reachItems.length > 0" class="info-tiles">
      <div class="tile is-ancestor has-text-centered">
        <div v-for="(type, index) in types" :key="index" class="tile is-parent">
          <article class="tile is-child box">
            <p class="title">{{ getTimesUsed(type) }}</p>
            <p class="subtitle">{{ getTypeName(type) }}</p>
          </article>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import tagProvider from '@/providers/tag'

export default {
  props: {
    tagId: {
      Default: 0,
      Type: Number,
    },
  },

  data() {
    return {
      types: [0, 1, 2, 4],
      reachItems: [],
    }
  },

  created() {
    this.getTagReach()
  },

  methods: {
    getTagReach() {
      tagProvider.methods.getTagReach(this.tagId).then((response) => {
        if (response.status === 200) {
          this.reachItems = response.data
        }
      })
    },

    getTypeName(type) {
      let name = ''

      switch (type) {
        case 0:
          name = 'Profiles'
          break
        case 1:
          name = 'Articles'
          break
        case 2:
          name = 'Events'
          break
        case 4:
          name = 'Questions'
          break
      }

      return name
    },

    getTimesUsed(type) {
      let result = 0

      let reach = this.reachItems.find((r) => r.Type === type)
      if (reach) {
        result = reach.TimesUsed
      }

      return result
    },
  },
}
</script>
